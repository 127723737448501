var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "setList-wrapper" },
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "基础设置", name: "first" } }, [
            _c(
              "div",
              { staticClass: "second-tab" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName1,
                      callback: function ($$v) {
                        _vm.activeName1 = $$v
                      },
                      expression: "activeName1",
                    },
                  },
                  [
                    _c("el-tab-pane", { attrs: { name: "theme" } }, [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-star-on" }),
                        _vm._v("主题设置"),
                      ]),
                    ]),
                    _c(
                      "el-tab-pane",
                      { attrs: { name: "login", disabled: true } },
                      [
                        _c(
                          "span",
                          { attrs: { slot: "label" }, slot: "label" },
                          [
                            _c("i", { staticClass: "el-icon-star-on" }),
                            _vm._v("登录设置"),
                          ]
                        ),
                      ]
                    ),
                    _c("el-tab-pane", { attrs: { name: "menu" } }, [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("i", { staticClass: "el-icon-star-on" }),
                        _vm._v("菜单设置"),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-tab-pane",
            { attrs: { label: "移动端", name: "second", disabled: true } },
            [_vm._v("移动端")]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "业务自定义", name: "third", disabled: true } },
            [_vm._v("业务自定义")]
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "服务集成", name: "fourth", disabled: true } },
            [_vm._v("服务集成")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }