<template>
  <div class="setList-wrapper">
    <el-tabs v-model="activeName">
      <el-tab-pane label="基础设置" name="first">
        <!-- <checkbox-plus type="radio" :options="orgCheckOps" :value.sync="isUseOtherOrgName" @onChange="onUseOtherOrgChange" /> -->
        <div class="second-tab">
          <el-tabs v-model="activeName1" @tab-click="handleClick">
            <el-tab-pane name="theme"><span slot="label"><i class="el-icon-star-on"></i>主题设置</span></el-tab-pane>
            <el-tab-pane name="login" :disabled="true" ><span slot="label"><i class="el-icon-star-on"></i>登录设置</span></el-tab-pane>
            <el-tab-pane name="menu"><span slot="label"><i class="el-icon-star-on"></i>菜单设置</span></el-tab-pane>
          </el-tabs>
        </div>
      </el-tab-pane>
      <el-tab-pane label="移动端" name="second" :disabled="true"
        >移动端</el-tab-pane
      >
      <el-tab-pane label="业务自定义" name="third" :disabled="true"
        >业务自定义</el-tab-pane
      >
      <el-tab-pane label="服务集成" name="fourth" :disabled="true"
        >服务集成</el-tab-pane
      >
    </el-tabs>
  </div>
</template>

<script>
import Vue from "vue";
import { Tabs, TabPane } from "element-ui";
Vue.use(Tabs);
Vue.use(TabPane);
import { CheckboxPlus } from "components/bussiness";
export default {
  name: "setList",
  components: {
    CheckboxPlus,
  },
  data() {
    return {
      activeName: "first",
      activeName1: "theme",
      isUseOtherOrgName: 1,
      orgCheckOps: [
        {
          label: "主题设置",
          value: 1,
        },
        {
          label: "登录设置",
          value: 2,
          disabled: true,
        },
        {
          label: "菜单设置",
          value: 3,
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name == "menu") {
        this.$router.push({
          name: "menu",
        });
      }
    },
    onUseOtherOrgChange(val) {
      console.log(val, "000");
      if (val == 2) {
        this.$router.push({
          name: "menu",
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.setList-wrapper {
  padding: 0 30px;
  .second-tab {
    padding-left: 30px;
  }
}
</style>
